import React, { useState, useMemo, useEffect } from "react";
import style from "./style.module.css";
import useTheme from "../../hooks/use_theme";

// -- Libs
import { COUNTRIES } from "../../lib/constants";
import { classNames, wait } from "../../lib/helpers";
import { fetchStoryGroups } from "../../lib/api";
import { loadImages } from "../../lib/images";

// -- Modules
import MasonryLayout from "../../components/MasonryLayout";
import SingleItem from "../../components/SingleItem";
import PageHeader from "../../components/PageHeader";
import Credit from "../../components/Credit";
import Meta from "../../components/Meta";
import ActiveMap from "../../maps/ActiveMap";

// -- Hooks
import useLanguage from "../../hooks/use_language";
import useSetMapCountryFromHistory from "../../hooks/use_set_map_country_from_history";

const initial = {
  first: null,
  rest: [],
  offset: 0,
  loading: true,
  end: false,
};


export default function (props) {
  const { match, history = {}, delay = 0, perPage = 20 } = props,
    country = match.params.country;

  // -- State
  const [state, setState] = useState(initial),
    {
      first = null,
      rest = [],
      offset = 0,
      loading = false,
      end = false,
    } = state;

  const i18n = useLanguage();
  useTheme("light");

  useMemo(() => {
    setState(initial);
  }, [country]);

  const mapCountryFromHistory = useSetMapCountryFromHistory(country, history),
    countries = mapCountryFromHistory ? [mapCountryFromHistory] : [],
    deepdivesPageTitle = i18n("metadata", "deep_dive", "title") || "Deep Dives",
    countryModifier = country ? `: ${i18n("countries", country.toLowerCase())}` : ''

  useEffect(() => {
    loadImages();
  })

  return (
    <>
      <Meta
        title={`${deepdivesPageTitle}${countryModifier}`}
        description={i18n("meta", "deepdives", "description")}
      />
      <article className="body">
        <PageHeader collapseOnScroll>
          <h1>
            {deepdivesPageTitle}
            {countryModifier}
          </h1>
        </PageHeader>
        <div className={classNames("grid", "constrain")}>
          <div className="grid--item__third">
            <ActiveMap onTileClick={tileClickHandler} countries={countries} />
          </div>
          {first && (
            <div className="grid--item__two-thirds">
              <SingleItem
                data={first}
                size={3}
                after={() => {
                  return <Credit className={style.credit} />;
                }}
              />
            </div>
          )}
        </div>
        <MasonryLayout
          entries={rest}
          showType={false}
          className={classNames(style.masonryContainer, "constrain")}
          onScrollEnd={onScrollEnd}
          isLoaded={!loading}
          enabled={!end}
          noResults={!!first ? "" : undefined}
        />
      </article>
    </>
  );

  async function onScrollEnd() {
    setState({ ...state, loading: true });
    const [deepdives] = [
      await fetchDeepDives(country, perPage, offset),
      await wait(delay),
    ],
      first = state.first || deepdives.shift();
    setState({
      first,
      rest: rest.concat(deepdives),
      offset: offset + perPage,
      loading: false,
      end: deepdives.length < perPage,
    });
  }

  function tileClickHandler(id) {
    history.push(`/deep-dives/country/${id}`);
  }
}

/**
 * Fetch deep dives
 * @param {string} countryId
 * @param {number} limit
 * @param {number} offset
 * @return {[error, []Entry]}
 */
async function fetchDeepDives(countryId = "", limit = 20, offset = 0) {
  const [error, articles = []] = await fetchStoryGroups({
    types: ["deepdive"],
    limit,
    offset,
    tags: countryId ? [`country-${COUNTRIES[countryId].slug}-published`] : [],
  });
  if (error) {
    console.error(error);
  }
  return articles;
}
