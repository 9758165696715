import React, { useEffect } from "react";

// -- Libs
import { BLUE } from "../../lib/constants";

// -- Modules
import Map, { ZoomOutButton } from "../../components/Map";
import MapKey from "../../components/MapKey";

// -- Hooks
import useLanguage from "../../hooks/use_language";
import useStore from "storeon/react";

const ActiveMap = (props = {}) => {
  const {
    countries = [],
    onTileClick = () => { },
    zoomOut = () => { },
    className = ""
  } = props;

  // -- Hooks
  const i18n = useLanguage(),
    { articleCounts = {}, dispatch } = useStore("articleCounts");

  const { data = {} } = articleCounts,
    [country] = countries;

  useEffect(() => {
    dispatch("articleCounts/fetch");
  }, []);

  return (
    <Map
      countries={countries}
      tileFill={tileFill}
      tileClickHandler={onTileClick}
      geographyActiveFill={BLUE}
      className={className}
    >
      {country && (
        <button onClick={zoomOut}>
          <ZoomOutButton />
        </button>
      )}
      <MapKey
        label={i18n("map_keys", "active") || "Active"}
        color={BLUE}
      />
    </Map>
  );

  /**
   * Set the fill on the map
   * Solid blue indicates there is data from that country
   * @param {string} id - country id
   * @param {boolean} inEu - whether country is in EU and/or UK
   * @returns {string} color
   */
  function tileFill(id, inEUorUK) {
    const count = data[id];
    if(id === 'GB') return BLUE; //exception for UK
    if (!inEUorUK || typeof count !== "number") return false;
    return count === 0 ? `hsl(196, 88%, 100%)` : BLUE;
  }
};

export default ActiveMap;
