import { fetchPath, fetchSquirroPath, standardRange } from "../lib/api";

export default store => {
  store.on("@init", () => ({
    entriesTotal: {
      data: 0,
      error: null,
      pending: true
    }
  }));

  store.on("entriesTotal/fetch", fetch);

  store.on("entriesTotal/pending", ({ entriesTotal = {} }) => {
    return {
      ...entriesTotal,
      pending: true
    };
  });

  store.on("entriesTotal/save", (_, entriesTotal = {}) => {
    return { entriesTotal };
  });

  /**
   * entriesTotal reducer
   * @param {object}
   * @fires [entriesTotal/pending]
   * @fires [entriesTotal/save]
   */
  async function fetch({ entriesTotal = {} }) {
    const { data = 0 } = entriesTotal;
    if (data) return entriesTotal;
    store.dispatch("entriesTotal/pending");
    // const [error, res] = await fetchPath("/activity/counter/", standardRange);
    const [error, res] = await fetchSquirroPath("/counter/", standardRange);
    store.dispatch("entriesTotal/save", {
      error,
      data: res ? res.total : {}
    });
  }
};
