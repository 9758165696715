import React from "react";
import styles from "./style.module.css";

// -- Libs
import { formatDate, classNames, elipse, isDebug } from "../../lib/helpers";
import { NO_CROP_TAG } from "../../lib/constants";

// -- Modules
import Small from "./Small";
import Medium from "./Medium";
import Large from "./Large";
import ExtraLarge from "./ExtraLarge";
import DocumentTypeIcon from "../DocumentTypeIcon";

export default React.memo(function SingleItem(props) {
  const {
    data = {},
    className = "",
    size = 0,
    children = [],
    isWide = false,
    after = () => ""
  } = props,
    {
      entities = [],
      date,
      value = "",
      tags = [],
      documentSubtypes = []
    } = data;

  const [view] = entities.map(({ views }) => views),
    isImage = view && !!Object.keys(view).length,
    noCrop = tags.includes(NO_CROP_TAG),
    [mediaType = ""] = documentSubtypes,
    mediaTypeIcon = (
      <DocumentTypeIcon
        tagName={mediaType}
        className={styles.documentTagIcon}
      />
    );


  return (
    <div
      className={classNames(
        styles.root,
        className,
        styles[`size-${size}`],
        isImage ? styles.isImage : "",
        isDebug() ? styles.debug : ""
      )}
    >
      {children}
      {size === 0 && (
        <Small
          //date={null}
          {...props}
          {...data}
           date={formatDate(date, { month: "short" })}

          value={isWide ? elipse(value, 30) : elipse(value, 16)}

          mediaTypeIcon={mediaTypeIcon}
        />
      )}
      {size === 1 && (
        <Medium
          {...props}
          {...data}
          // date={formatDate(date, { month: "short" })}
          date={null}
          value={elipse(value, 25)}

          mediaTypeIcon={mediaTypeIcon}
        />
      )}
      {size === 2 && (
        <Large
          {...props}
          {...data}
          // date={formatDate(date)}
          date={null}
          value={elipse(value, 25)}

          noCrop={noCrop}
          mediaTypeIcon={mediaTypeIcon}
        />
      )}
      {size === 3 && (
        <ExtraLarge
          {...props}
          {...data}
          value={elipse(value, 25)}
          view={view}
          mediaTypeIcon={mediaTypeIcon}
          after={after}
        />
      )}
    </div>
  );
});

export { Small, Medium, Large, ExtraLarge };
