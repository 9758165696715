import React from "react";
import styles from "./style.module.css";
import withData from "./with-data";

// -- Libs
import { wait } from "../../lib/helpers";
import { EVENT_FOCUS } from "../../lib/constants";

// -- Components
import Timeline from "../Timeline";

// -- Hooks
import useLanguage from "../../hooks/use_language";
import useTimeSlices from "../../hooks/use_timeslices";

// pad min-date by a this amount in MS
const timePadding = 50000000000;

const RelationshipTimeline = (props = {}) => {
  const { countryTag = "", timeline = [], statusHistory = [] } = props;

  const { timeSlices, timeSliceIndex, setTimeSliceIndex, minDate } =
    useTimeSlices([...timeline, ...statusHistory], timePadding);

  // -- State
  const i18n = useLanguage();


  return (
    <section className={styles.root}>
      {timeline.error || (
        <Timeline
          className={styles.timelineContainer}
          tag={countryTag}
          events={timeline}
          eventFocusEvent={EVENT_FOCUS}
          minDate={minDate}
          canExpandScope={canExpandScope()}
          expandScope={expandScope}
          setEventFocus={setEventFocus}
        >
          <div className={styles.header}>
            <h2>{i18n("relationship_status", "timeline_header")}</h2>
          </div>
        </Timeline>
      )}
    </section>
  );

  function expandScope() {
    canExpandScope() && setTimeSliceIndex(timeSliceIndex + 1);
  }

  function canExpandScope() {
    return timeSliceIndex < timeSlices.length - 1;
  }

  async function setEventFocus(date) {
    wait(200);
    if (minDate > date && canExpandScope()) {
      expandScope();
      wait(400);
    }
  }
};

export default withData(RelationshipTimeline);
