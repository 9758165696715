import React from "react";
import styles from "./style.module.css";
import { Transition } from "react-transition-group";
import { toPathString } from "flubber";
import { memoize } from "lodash";

// -- Libs
import { data as europe } from "../../lib/europe_map";
import { classNames } from "../../lib/helpers";
import { isEU } from "../../lib/countries";
import { COUNTRIES } from "../../lib/constants";

// -- Components
import Svg from "./Svg";
import Tile from "./Tile";
import ZoomOutButton from "./ZoomOutButton";

export { ZoomOutButton };

const striped = (
  <pattern
    id="striped"
    width="8"
    height="12"
    patternUnits="userSpaceOnUse"
    patternTransform="rotate(45 50 50)"
  >
    <line stroke="white" strokeWidth="120px" y2="12" />
    <line stroke="rgba(0,0,0,0.1)" strokeWidth="7px" y2="10" />
  </pattern>
);

/**
 * Geographic represnetation of Europe
 * @param {object} props
 * @param {string} props.id
 * @param {string} props.fill
 * @param {d} props.d - the d value of an svg
 */
const Geography = React.memo(function (props) {
  const { id = "", fill = "", d = "" } = props;
  return <path d={d} id={id} style={{ fill }} />;
});

const euCountries = Object.entries(COUNTRIES).reduce((acc, [key, { inEU }]) => {
  if (inEU) {
    acc.push(key);
  }
  return acc;
}, []);

export default React.memo(function (props) {
  const {
    renderTile,
    tileFill = tileFillDefault,
    textFill = textFillDefault,
    tileClickHandler,
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    countries = [],
    children,
    geographyFill = "rgba(20,10,0,0.25)",
    geographyActiveFill = "rgb(102,206,245)",
    nonEuTileFill = "url(#striped)",
    euTileFill = "rgb(102,206,245)",
    euTextFill = "black",
    nonEuTextFill = "rgba(0,0,0,0.25)",
    animationTime = 700,
    className = "",
  } = props;

  const tileFillMem = memoize(tileFill),
    textFillMem = memoize(textFill),
    [country] = countries,
    highlighted = country === "EU" ? euCountries : countries;

  if (renderTile && typeof renderTile !== "function") {
    throw new Error("renderTile must be function which retuns a MapTile");
  }

  // -- Render
  return (
    <div
      className={classNames(styles.root, country && styles.focused, className)}
    >
      <div className={styles.children}>{children}</div>
      <Transition in={!!country} timeout={animationTime}>
        {(state) => {
          switch (state) {
            case "entered":
              return zoomedIn(state);
            case "entering":
              return zoomedOut(state);
            case "exiting":
              return zoomedIn(state);
            case "exited":
              return zoomedOut(state);
            default:
              return "null";
          }
        }}
      </Transition>
    </div>
  );

  function tileCoordToSvg(id) {
    const { tile } = europe[id];
    if (!tile) return "";
    const _isEUorUK = isEU(id) || id === 'GB';
    let fill = tileFillMem.call(this, id, _isEUorUK);
    const text = textFillMem.call(this, id, _isEUorUK);

    // tileFill can return a boolean, handled here
    if (typeof fill === "boolean") {
      fill = fill ? euTileFill : nonEuTileFill;
    }

    const isClickable = fill && fill !== `hsl(196, 88%, 100%)`;

    return (
      <Tile
        fill={fill}
        clickHandler={
          (_isEUorUK) && isClickable ? tileClickHandler.bind(this, id) : null
        }
        d={dFromTileData(tile)}
        id={id}
        key={id}
        tile={tile}
        showLabels={!country}
        labelFn={threeLetterCountryCode}
        textColor={text}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    );
  }

  function threeLetterCountryCode(id) {
    try {
      return COUNTRIES[id].code;
    } catch (e) {
      return id;
    }
  }

  function zoomedOut(state = "") {
    return (
      <Svg
        state={state}
        tileFill={tileFillMem}
        textFill={textFillMem}
        {...props}
        getD={dFromTileData}
      >
        {Object.keys(europe).map((k) => {
          return tileCoordToSvg(k);
        })}
        {striped}
      </Svg>
    );
  }

  function zoomedIn(state = "") {
    return (
      <Svg
        state={state}
        getD={dFromTileData}
        tileFill={tileFillMem}
        textFill={textFillMem}
        {...props}
      >
        {Object.keys(europe).map((k) => {
          const isActive = highlighted.includes(k),
            fill = isActive ? geographyActiveFill : geographyFill,
            geo = <Geography id={k} fill={fill} d={europe[k].d} key={k} />;

          return isActive ? (
            <a
              onMouseEnter={onMouseEnter.bind(this, k)}
              onMouseLeave={onMouseLeave.bind(this, k)}
              key={k}
            >
              {geo}
            </a>
          ) : (
            geo
          );
        })}
      </Svg>
    );
  }

  /**
   * Default tile fill function
   * Highlights EU countries with params.euTileFill and others with props.tileFill
   * @param {string} id
   * @param {boolean} isInEU - if country is in EU
   * @returns {string} a color
   */
  function tileFillDefault(_, isInEU) {
    return isInEU ? euTileFill : nonEuTileFill;
  }

  /**
   * Default tile fill function
   * Highlights EU countries with params.euTileFill and others with props.tileFill
   * @param {string} id
   * @param {boolean} isInEU - if country is in EU
   * @returns {string} a color
   */
  function textFillDefault(id, isInEU) {
    return isInEU || id === "GB" ? euTextFill : nonEuTextFill;
  }
});

function dFromTileData(tile) {
  const gap = 11;
  const multiplier = 110;
  const topOffset = 1;
  const [c1, c2] = tile,
    x = c2 * multiplier + gap * c2,
    y = c1 * multiplier + gap * c1 + topOffset,
    d = toPathString([
      [x, y],
      [x + multiplier, y],
      [x + multiplier, y + multiplier],
      [x, y + multiplier],
    ]);
  return d;
}
