import React from 'react';
import styles from './style.module.css';

export default React.memo(function(props) {
  const { label = '', color = '' } = props;
  return (
    <div className={styles.root}>
      <span className={styles.label}></span>
      <div className={styles.color}>
          <div style={{ background: color }} />
      </div>
      <span className={styles.label}>{label}</span>
    </div>
  );
});
