import React, { useState, useEffect } from "react";
import styles from "./style.module.css";
import useTheme from "../../hooks/use_theme";
import { sortByDate } from "../../lib/date";

// -- Libs
import { classNames, wait } from "../../lib/helpers";
import { getSlugFromId } from "../../lib/countries";
import { reduceArticleCounts } from "./lib";

// -- Modules
import CountryDropdown from "../../components/CountryDropdown";
import NotFound from "../NotFound";
import RelationshipTimeline from "../../components/RelationshipTimeline";
import PageHeader from "../../components/PageHeader";
import StoryGroup from "../../components/StoryGroup";
import Credit from "../../components/Credit";
import ActiveMap from "../../maps/ActiveMap";
import IndexView from "./IndexView";
import Meta from "../../components/Meta";
import Image from "../../components/Image";

// Hooks
import useLanguage from "../../hooks/use_language";
import useSetMapCountryFromHistory from "../../hooks/use_set_map_country_from_history";
import useStoreon from "storeon/react";

export default function (props) {
  let bannerSlot = null;
  const { country = "", history = {} } = props;

  useTheme("light");
  const i18n = useLanguage(),
    mapCountryId = useSetMapCountryFromHistory(country, history);

  const [err, { featured, published }] = getTags(country),
    slug = getSlugFromId(country),
    displayTitle = country || i18n("relationship", "europe"),
    translatedContryName = country
      ? i18n("countries", displayTitle.toLowerCase())
      : displayTitle,
    unitedState = i18n("countries", "us");

  if (err) {
    console.error(err);
    return <NotFound />;
  }

  if (!featured && !published) return "";

  // -- State
  const {
      articleCounts = {},
      deepdives = {},
      entriesTotal = {},
      relationships = {},
      statusHistory: { data: statusHistory } = {},
      events = {},
      dispatch,
    } = useStoreon(
      "articleCounts",
      "deepdives",
      "entriesTotal",
      "relationships",
      "statusHistory",
      "events"
    ),
    [hoverCountry, setHoverCountry] = useState(null);

  useEffect(() => {
    if (country) return;
    dispatch("articleCounts/fetch");
    dispatch("deepdives/fetch", 100, 0);
    dispatch("entriesTotal/fetch");
    dispatch("events/fetch");
  }, [country]);

  const {
    meta: { tag = "" } = {},
    id,
    webshot_url,
  } = relationships.data.find(({ keywords: { tag } }) => {
    return `${tag}-published` === published;
  }) || {};

  const { data: articleCountsData = {} } = articleCounts,
    { data: deepdiveData = {} } = deepdives,
    { all: allDeepDives = [] } = deepdiveData,
    { data: eventsData } = events,
    searchStats = reduceArticleCounts(articleCountsData);

  // get the last updated
  useEffect(() => {
    const statusHistories = statusHistory[tag] || [];
    const { date } = statusHistories.sort(sortByDate)[0] || {};
    if (!date) {
      return;
    }
    dispatch("lastUpdated/update", { id: country, date: new Date(date) });
  }, [dispatch, statusHistory]);

  const foundationInfo = i18n(`powered_by_${country}`);
  let foundation, link;
  if (foundationInfo) {
    try {
      foundation = foundationInfo.match(/\[(.*?)\]/)[1];
      link = foundationInfo.match(/\((.*?)\)/)[1];
    } catch (e) {
      foundation = "";
      link = "";
    }
  }

  const pageHeader = !!country ? (
    <PageHeader
      collapseOnScroll
      after={() => (
        <Credit
          foundation={foundation}
          link={link}
        />
      )}
    >
      <h1>
        {unitedState} &
        <CountryDropdown
          className="big"
          initialValue={translatedContryName}
          onChange={onDropDownSelect}
        />
      </h1>
    </PageHeader>
  ) : (
    <PageHeader>
      <h1>{i18n("metadata", "relationship", "title")}</h1>
    </PageHeader>
  );

  if (!country) {
    const { webshot_url } =
      relationships.data.find(({ title }) => title === "European Union") || {};

    bannerSlot = webshot_url ? (
      <a href="relationship/EU" className={styles.euLink}>
        <p>{i18n("dashboard", "eu-us")} </p>
        <Image
          src={webshot_url}
          alt="European Union"
          cropRatio={0.49}
          lazy={false}
        ></Image>
      </a>
    ) : null;
  } else {
    bannerSlot = (
      <RelationshipTimeline
        countryCode={country}
        countryTag={published}
        slug={slug}
        monthInterval={1}
      />
    );
  }

  // -- Render
  return (
    <>
      <Meta
        title={`${unitedState || "United States"} & ${
          translatedContryName || country
        }`}
        description={i18n("meta", "relationships", "description")}
      />
      <section className={classNames("body", styles.root)}>
        {pageHeader}

        <div className={classNames("grid", "constrain")}>
          <div className="grid--item__third">
            <ActiveMap
              className={styles.map}
              onTileClick={(id) => history.push(`/relationship/${id}`)}
              zoomOut={() => history.push(`/relationship/`)}
              countries={[mapCountryId]}
              hoverCountry={hoverCountry}
              setHoverCountry={setHoverCountry}
            />
          </div>
          <div className="grid--item__two-thirds">{bannerSlot}</div>
        </div>
        {/* ====== Second row ======== */}
        {country ? (
          <StoryGroup
            history={history}
            featuredTag={featured}
            publishedTag={published}
            rowSize={4}
            relatedStoryGroupTag={published}
            socialMediaTag={`country-${getSlugFromId(country)}-published`}
            delay={500}
            id={country}
          />
        ) : (
          <IndexView
            deepdives={deepdives}
            allDeepDives={allDeepDives}
            entriesTotal={entriesTotal}
            searchStats={searchStats}
            events={eventsData}
          />
        )}
      </section>
    </>
  );

  /**
   * Handler for country dropdown
   * @param {Object} param
   * @param {string} param.value
   */
  async function onDropDownSelect({ value }) {
    history.replace(`/relationship`);
    await wait(200);
    history.replace(`/relationship/${value}`);
  }

  /**
   * Get featured/published tag for country
   * @param {string} countryId
   * @returns {[]Array} out
   * @returns {string} out.featured
   * @returns {string} out.published
   */
  function getTags(countryId = "") {
    let out = {
      featured: null,
      published: null,
    };
    let err = null;
    if (countryId) {
      if (!getSlugFromId(countryId)) {
        return [
          new Error(
            `Could not find relationship data for country code ${country}`
          ),
          out,
        ];
      }
      return [
        err,
        {
          featured: `country-${getSlugFromId(countryId)}-featured`,
          published: `country-${getSlugFromId(countryId)}-published`,
        },
      ];
    } else {
      return [
        err,
        {
          featured: "countries-europe-featured",
          published: "countries-europe-published",
        },
      ];
    }
  }
}
