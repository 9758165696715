import "unfetch/polyfill";

export default store => {
    store.on("@init", () => ({
        events: {
            data: {},
            error: null,
            pending: true,
            fetched: false
        }
    }));

    store.on("events/fetch", async ({ events = {} }) => {
        const { fetched = false } = events;
        if (fetched) return;

        try {
            const res = await fetch(`${process.env.PUBLIC_URL}/events.json`)
            const data = await res.json();

            store.dispatch("events/save", {
                data,
                pending: false,
                fetched: true,
                error: null
            });
        } catch(error) {
            console.error(error)
            return {
                data: null,
                pending: false,
                fetched: false,
                error
            }
        }
    });

    store.on("events/save", (_, events = {}) => {
        return { events };
    });
};
