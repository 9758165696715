import React, { useState, useEffect } from "react";
import useStoreon from "storeon/react";
import styles from "./style.module.css";
import queryString from "query-string";
import useTheme from "../../hooks/use_theme";

// -- Style
import stickySlidedownTransition from "../../styles/transitions/stickySlidedown.module.css";
import collapse from "../../styles/transitions/collapse.module.css";
import fade from "../../styles/transitions/fade.module.css";

// -- Libs
import { classNames, wait, formatDate } from "../../lib/helpers";
import {fetchPath, fetchSquirroPath, standardRange} from "../../lib/api";
import { LANGUAGES } from "../../lib/constants";
import { getDatesFromRange, dateToString } from "../../lib/date";
import {
  parseQueryString,
  fetchResults,
  translateQueryStrings,
  removeFromArray,
  arrayFromString,
  PAGE_SIZE,
} from "./lib";

// -- Modules
import { useInView } from "react-intersection-observer";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import MasonryLayout from "../../components/MasonryLayout";
import TextSearch from "../../components/TextSearch";
import ExpandedItemModal from "../../components/ExpandedItemModal";
import DatePicker from "../../components/DatePicker";
import Dropdown from "../../components/Dropdown";
import PageHeader from "../../components/PageHeader";
import SubMenu from "../../components/Submenu";
import Meta from "../../components/Meta";
import TrendingTerms from "../../components/TrendingTerms";
import ActiveMap from "../../maps/ActiveMap";
import ArticleCount from "./ArticleCount";
import TagSearch from "./TagSearch";
import AdvancedFilterControls from "./AdvancedFilterControls";
import Tag from "./Tag";
import BackToTop from "./BackToTop";

// -- Hooks
import { useLocation } from "react-router-dom";
import useLanguage from "../../hooks/use_language";
import useSetMapCountryFromHistory from "../../hooks/use_set_map_country_from_history";

const dropDownInitial = { value: "", label: "All" };

const isNearTop = false;

export default function (props) {
  const { history = {} } = props,
    query = parseQueryString(
      "topic",
      "country",
      "terms",
      "people",
      "locations",
      "organizations",
      "date",
      "issue",
      "lang",
      "sort",
      "type"
    ),
    { from, to } = getDatesFromRange(query.date);

  const i18n = useLanguage();
  const location = useLocation()

  const mapCountryId = useSetMapCountryFromHistory(query.country, history);

  // -- Store
  const { tags, loadingState, dispatch } = useStoreon("tags", "loadingState"),
    { issue: issues = [] } = tags,
    contentTypes = [
      dropDownInitial,
      ...Object.keys(tags.contentType).map((value) => ({
        value,
        label: i18n("document_types", value) || value,
      })),
    ];

  // -- View state
  const [data, setData] = useState([]), // the articles appearing in Masonry
    [shownFilters, setShownFilters] = useState([]), // the filter inputs (e.g., People, Locations)
    [isEndOfResults, setIsEndOfResults] = useState(false);

  // -- Dropdown options state
  const languages = [
    {
      value: "",
      label: i18n("explore", "placeholders", "all"),
    },
    ...LANGUAGES.map(({ id }) => {
      return {
        value: id,
        label: i18n("languages", id) || id,
      };
    }),
  ],
    sortFilters = [
      {
        label: i18n("explore", "sort_filter_values", "latest"),
        value: "latest",
      },
      {
        label: i18n("explore", "sort_filter_values", "relevancy"),
        value: "relevancy",
      },
    ];

  // -- Filter state
  const [countriesFilter, setCountriesFilter] = useState(query.country || ""),
    [topicFilters, setTopicFilters] = useState(arrayFromString(query.topic)),
    [searchFilter, setSearchFilter] = useState(query.terms || ""),
    [typeFilter, setTypeFilter] = useState(query.type || ""),
    [peopleFilter, setPeopleFilter] = useState(arrayFromString(query.people)),
    [locationsFilter, setLocationsFilter] = useState(
      arrayFromString(query.locations)
    ),
    [organizationsFilter, setOrganizationsFilter] = useState(
      arrayFromString(query.organizations)
    ),
    [issueFilters, setIssueFilters] = useState(arrayFromString(query.issue)),
    [sortParam, setSortParam] = useState(query.sort || ""),
    [offset, setOffset] = useState(0),
    [count, setCount] = useState(null),
    [filtersOpen, setFiltersOpen] = useState(false),
    [language, setLanguage] = useState(query.lang || ""),
    [fromDate, setFromDate] = useState(from),
    [toDate, setToDate] = useState(to);

  // -- Intersection Observers
  const [menuRef, isMenuInView] = useInView([]),
    [filterSummaryRef, isFilterSummaryInView] = useInView([]),
    [topMarkerRef, isTop] = useInView([]);

  // Show initial filters
  useEffect(() => {
    const { topic, locations, people, organizations, issue } = query,
      filters = [];
    topic && filters.push("Topics");
    locations && filters.push("Locations");
    people && filters.push("People");
    organizations && filters.push("Organizations");
    issue && filters.push("Issues");
    showFilters(...filters);
  }, [setShownFilters]);

  // Update Query string in location.search
  // Doing this should also update actual request to the backend API
  useEffect(() => {
    // set falsy values to undefined so they aren't stringified by queryString
    // e.g, if type = '' this should not appear in the query String
    history.push({
      search: queryString.stringify({
        topic: topicFilters.join(",") || undefined,
        issue: issueFilters.join(",") || undefined,
        organizations: organizationsFilter.join(",") || undefined,
        locations: locationsFilter.join(",") || undefined,
        people: peopleFilter.join(",") || undefined,
        type: typeFilter || undefined,
        country: countriesFilter || undefined,
        terms: searchFilter || undefined,
        lang: language || undefined,
        date:
          fromDate && toDate
            ? `${dateToString(fromDate)}-${dateToString(toDate)}`
            : undefined,
        sort: sortParam || undefined,
      }),
      hash: history.location.hash,
    });
  }, [
    typeFilter,
    countriesFilter,
    searchFilter,
    topicFilters,
    issueFilters.length,
    peopleFilter.length,
    locationsFilter,
    organizationsFilter,
    toDate,
    fromDate,
    sortParam,
    language,
  ]);

  // Sync url with state in some cases
  useEffect(() => {
    const {issue} = parseQueryString('issue')
    if (issue) {
      setIssueFilters([issue])
    }
  },[location.search])

  // New query params
  useEffect(() => {
    setOffset(0);
    setIsEndOfResults(false);
    const params = translateQueryStrings(window.location.search);
    setData([]);
    // updateCount(params);
  }, [window.location.search]);

  // /**
  //  * Sets the count state after a fetch request
  //  * @param {object} params
  //  */
  // async function updateCount(params = {}) {
  //   // const [error, res] = await fetchPath("/activity/counter/", {
  //     const [error, res] = await fetchSquirroPath("/api/entries", {
  //     ...standardRange,
  //     ...params
  //   });
  //   if (error) {
  //     console.error(error);
  //     setCount(0);
  //     return;
  //   }
  //   setCount(res.count);
  // }

  /**
   * Concatenate data with new results
   * Sets the new data with setData
   * @param {[]Entry} data
   * @param {object} query
   * @param {number} offset
   * @param {number} delay
   */
  async function concatResults(data = [], query = {}, offset = 0, delay = 0) {
    dispatch("updateLoadingState", "loading");
    const [[entries = [], count = 0]] = [
      await fetchResults({...query, sort: 'latest', 'sort-script-id': 'relevancy' }, offset),
      await wait(delay),
    ];
    entries.length < PAGE_SIZE && setIsEndOfResults(true);
    dispatch("updateLoadingState", "loaded");
    entries && setData(data.concat(entries));
    setOffset(offset + PAGE_SIZE);
    setCount(count);
  }

  async function fetchSuggestions(path, options, text) {
    if (!text) return;
    const [error, suggestions] = await fetchSquirroPath(
      path,
      Object.assign(options, { text })
    );
    if (error) {
      console.error(error);
      return [];
    }

    return [...new Set(suggestions.suggestions.map(({ value }) => value))];
  }

  const issueTags = Object.values(issues).map(({ name, id }) => ({
    id,
    name,
  }));

  const searchFilters = {
      Issues: (
      <TagSearch
        fetchSuggestions={fetchSuggestions.bind(this, "/api/suggest", {
          limit: 5,
          categories: "tr.issues",
        })}
        value={issueFilters.map((name) => ({ id: name, name }))}
        onChange={setIssueFilters}
        placeholder={i18n("explore", "placeholders", "issues")}
      />
    ),
    Topics: (
      <TagSearch
        fetchSuggestions={fetchSuggestions.bind(this, "/api/suggest", {
          limit: 5,
          categories: "tr.topics",
        })}
        value={topicFilters.map((name) => ({ id: name, name }))}
        onChange={setTopicFilters}
        placeholder={i18n("explore", "placeholders", "topics")}
      />
    ),
    People: (
      <TagSearch
        fetchSuggestions={fetchSuggestions.bind(this, "/api/suggest", {
          limit: 5,
          subcategories: "people:person",
          categories: "tr.entities",
        })}
        value={peopleFilter.map((name) => ({ id: name, name }))}
        onChange={setPeopleFilter}
        placeholder={i18n("explore", "placeholders", "people")}
      />
    ),
    Organizations: (
      <TagSearch
        fetchSuggestions={fetchSuggestions.bind(this, "/api/suggest", {
          limit: 5,
          subcategories: "organization:organization",
          categories: "tr.entities",
        })}
        value={organizationsFilter.map((name) => ({ id: name, name }))}
        onChange={setOrganizationsFilter}
        placeholder={i18n("explore", "placeholders", "organizations")}
      />
    ),
    Locations: (
      <TagSearch
        fetchSuggestions={fetchSuggestions.bind(this, "/api/suggest", {
          limit: 5,
          subcategories: "location:location",
          categories: "tr.entities",
        })}
        value={locationsFilter.map((name) => ({ id: name, name }))}
        onChange={setLocationsFilter}
        placeholder={i18n("explore", "placeholders", "locations")}
      />
    ),
  };

  const menuToggleButton = (
    <CSSTransition
      classNames={fade}
      timeout={400}
      in={!isMenuInView || (filtersOpen && !isTop)}
    >
      <button
        onClick={toggleFilterSection}
        className={classNames(
          styles.menuToggle,
          filtersOpen ? styles.menuToggle_isOpen : ""
        )}
        disabled={isNearTop}
      >
        <i className="material-icons">
          {filtersOpen ? "expand_less" : "expand_more"}
        </i>
      </button>
    </CSSTransition>
  );

  const filterSummary = (
    <div className={styles.filterSummary}>
      <ArticleCount count={count} itemsFound={i18n("explore", "items_found")} />
      <div className={styles.filterSummary_filters}>
        {countriesFilter && (
          <Tag
            name={countriesFilter}
            remove={setCountriesFilter.bind(null, "")}
            setLabel={getCountryName.bind(this)}
          />
        )}
        {searchFilter && (
          <Tag name={searchFilter} remove={setSearchFilter.bind(null, "")} />
        )}
        {peopleFilter.map((person) => (
          <Tag
            key={person}
            name={person}
            remove={setPeopleFilter.bind(
              this,
              removeFromArray(peopleFilter, person)
            )}
          />
        ))}
        {issueFilters.map((id) => (
          <Tag
            key={id}
            name={id}
            remove={setIssueFilters.bind(
              this,
              removeFromArray(issueFilters, id)
            )}
            setLabel={getIssueName.bind(this)}
          />
        ))}
        {topicFilters.map((id) => (
          <Tag
            key={id}
            name={id}
            remove={setTopicFilters.bind(
              this,
              removeFromArray(topicFilters, id)
            )}
          />
        ))}
        {organizationsFilter.map((id) => (
          <Tag
            key={id}
            name={id}
            remove={setOrganizationsFilter.bind(
              this,
              removeFromArray(organizationsFilter, id)
            )}
          />
        ))}
        {locationsFilter.map((id) => (
          <Tag
            key={id}
            name={id}
            remove={setLocationsFilter.bind(
              this,
              removeFromArray(locationsFilter, id)
            )}
          />
        ))}
        {typeFilter && (
          <Tag
            name={typeFilter}
            remove={() => setTypeFilter("")}
            setLabel={i18n.bind(this, "document_types")}
          />
        )}
        {language && (
          <Tag
            name={language}
            remove={setLanguage.bind(null, "")}
            setLabel={i18n.bind(this, "languages")}
          />
        )}
        {toDate && fromDate && (
          <Tag
            name="date"
            remove={resetDates}
            label={`${formatDate(fromDate, {
              month: "short",
            })}-${formatDate(toDate, { month: "short" })}`}
          />
        )}
        {sortParam && (
          <Tag
            name={sortParam}
            remove={setSortParam.bind(null, "")}
            setLabel={i18n.bind(this, "explore", "sort_filter_values")}
          />
        )}
      </div>
    </div>
  );

  function resetDates() {
    setToDate(null);
    setFromDate(null);
  }

  // -- Render
  return (
    <>
      <Meta
        title={i18n("metadata", "explore", "title")}
        description={i18n("meta", "search", "description")}
      />
      <SubMenu sticky breadcrumb>
        <div className={styles.sectionsMenuSlot}>
          {isFilterSummaryInView || filterSummary}
          {menuToggleButton}
        </div>
      </SubMenu>
      <section className={classNames(styles.root, "body")}>
        <div ref={topMarkerRef} />
        <CSSTransition
          timeout={700}
          classNames={stickySlidedownTransition}
          in={filtersOpen}
        >
          <div className={classNames(styles.menu)} ref={menuRef}>
            <PageHeader collapseOnScroll>
              <h1>{i18n("metadata", "explore", "title")}</h1>
            </PageHeader>
            <div className={classNames("constrain", "grid")}>
              <div
                className={classNames(styles.mapContainer, "grid--item__third")}
              >
                <ActiveMap
                  countries={[mapCountryId]}
                  zoomOut={addCountryFilter.bind(null, null)}
                  onTileClick={addCountryFilter}
                />
              </div>
              <div className={classNames("grid--item__two-thirds", styles.filtersPanel)}>
                <AdvancedFilterControls
                  showFilters={showFilters}
                  removeFilter={removeFilter}
                  shownFilters={shownFilters}
                  searchFilters={searchFilters}
                />
                <div className={styles.filtersRow}>
                  <TextSearch
                    className={styles.keywordSearch}
                    name="terms"
                    value={searchFilter}
                    placeholder={i18n("explore", "placeholders", "keywords")}
                    autoSuggest
                    onChange={fetchSuggestions.bind(
                      this,
                      "/api/suggest",
                      {
                        categories: "keyphrases",
                        limit: 5,
                      }
                    )}
                    submit={setSearchFilter}
                    onClear={removeFilter.bind(this, "Terms")}
                    variant="primary"
                  />
                  <TransitionGroup>
                    {shownFilters.map((filterName) => {
                      return (
                        <CSSTransition
                          key={filterName}
                          timeout={500}
                          classNames={collapse}
                        >
                          <div className={styles.searchContainer}>
                            {searchFilters[filterName]}
                          </div>
                        </CSSTransition>
                      );
                    })}
                  </TransitionGroup>
                </div>
                <div className={styles.filtersRow}>
                  <Dropdown
                    className={styles.dropdown}
                    options={sortFilters}
                    value={sortParam}
                    onChange={(opt) => setSortParam(opt.value)}
                    placeholder={i18n("explore", "placeholders", "sort_by")}
                  />
                  <Dropdown
                    className={styles.dropdown}
                    options={contentTypes}
                    value={typeFilter}
                    onChange={(opt) => setTypeFilter(opt.value)}
                    placeholder={i18n(
                      "explore",
                      "placeholders",
                      "select_document_type"
                    )}
                  />
                  <Dropdown
                    className={styles.dropdown}
                    options={languages}
                    value={language}
                    onChange={(opt) => setLanguage(opt.value)}
                    placeholder={i18n("explore", "placeholders", "language")}
                  />
                  <DatePicker
                    placeholder={i18n("explore", "searchDates")}
                    fromDate={fromDate}
                    toDate={toDate}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                    className={styles.dropdown}
                    customDates={[
                      [i18n("explore", "last_week"), setCustomDate().lastWeek],
                      [
                        i18n("explore", "current_month"),
                        setCustomDate().currentMonth,
                      ],
                      [
                        i18n("explore", "last_30_days"),
                        setCustomDate().last30Days,
                      ],
                    ]}
                  />
                </div>
                <div className={styles.trendingTerms}>
                  <h3>{i18n("dashboard", "trending_terms")}</h3>
                  <TrendingTerms onSelect={setSearchFilter}/>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
        <div
          className={classNames(styles.filterSummary_large, "constrain")}
          ref={filterSummaryRef}
        >
          {filterSummary}
        </div>
        <MasonryLayout
          entries={data}
          onScrollEnd={onScrollEnd}
          showType={false}
          className={classNames(styles.collectionContainer, "constrain")}
          isLoaded={loadingState === "loaded"}
          enabled={!isEndOfResults}
        />
        <ExpandedItemModal />
        <BackToTop show={!isTop} />
      </section>
    </>
  );

  /**
   * Custom functions for setting dates
   * @return {object}
   * @return {object}
   * @return {object}
   * @return {object}
   */
  function setCustomDate() {
    const now = new Date();

    return {
      lastWeek,
      currentMonth,
      last30Days,
    };

    function lastWeek() {
      const lastWeekStart = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 7
      );
      setFromDate(lastWeekStart);
      setToDate(now);
    }

    function currentMonth() {
      const startOfThisMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      setFromDate(startOfThisMonth);
      setToDate(now);
    }

    function last30Days() {
      const startOf30Days = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 30
      );
      setFromDate(startOf30Days);
      setToDate(now);
    }
  }

  /**
   * Show/hide the top section with all the filters
   */
  function toggleFilterSection() {
    setFiltersOpen(!filtersOpen);
  }

  function addCountryFilter(countryId = "") {
    setCountriesFilter(countryId);
  }

  /**
   * Handle end of infinite scroll; refreshes results
   */
  function onScrollEnd() {
    concatResults(
      data,
      translateQueryStrings(window.location.search),
      offset,
      600
    );
  }

  /**
   * Show a filter ( add to list of shownfilters)
   * @param {...string} filters
   */
  function showFilters(...filters) {
    setShownFilters([...new Set([...shownFilters, ...filters])]);
  }

  /**
   * Remove a filter from the list of shown filters
   * @param {string} filter
   */
  function removeFilter(filter) {
    setShownFilters(shownFilters.filter((f) => f !== filter));
  }

  /**
   * Get an issue's name by id
   * @param {string} id
   * @return {string}
   */
  function getIssueName(id) {
    return (issues[id] && issues[id].name) || id;
  }

  /**
   * Get a country name
   * @param {string} id
   * @return {string}
   */
  function getCountryName(id) {
    return i18n("countries", id.toLowerCase()) || id;
  }
}
